.item {
    border-radius: 8px;
    background: #f0f4f7;
    overflow: hidden;
    height: 32px;
    text-decoration: none;
    cursor: pointer;
    display: inline-table;
}

.item:first-child {
    margin-left: 12px;
}

.item:last-child {
    margin-right: 12px;
}

.link {
    display: flex;
    align-items: center;
    gap: 4px;
    text-decoration: none;
    padding: 8px;
    height: 32px;
}

.linkSale {
    padding: 8px 10px;
}

.text {
    color: #18181b;
    text-align: center;
    white-space: nowrap;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: initial;
}

.accentedText {
    font-family: "Ali_ver.18_Lighter-Rounded", Inter, sans-serif;
    color: #000;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 85%;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    white-space: nowrap;
}

.italicText {
    font-style: italic;
}

.crossedText {
    text-decoration: line-through underline;
}

.underlinedText {
    text-decoration: underline;
}

@media (--snow-tablet) {
    .item:first-child {
        margin-left: 0;
    }

    .item:last-child {
        margin-right: 0;
    }
}

@media (--snow-tablet) and (max-width: 1024px) {
    .text {
        display: none;
    }

    .textWithoutIcon {
        display: initial;
    }

    .textWithSaleImage {
        display: none;
    }
}
