.diamonds {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    overflow-x: auto;
    scrollbar-width: none;
    margin-left: -12px;
    margin-right: -12px;
    list-style-type: none;
}

.diamonds::-webkit-scrollbar {
    display: none;
}

@media (--snow-tablet) {
    .diamonds {
        margin-left: 0;
        margin-right: 0;
    }
}
